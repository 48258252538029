import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "path": "/developer/optimizing-asp-net-mvc-sites",
  "date": "2013-04-29",
  "title": "OPTIMIZING ASP.NET MVC SITES",
  "author": "admin",
  "tags": ["development", "csharp", "asp.net"],
  "featuredImage": "feature.jpg",
  "excerpt": "In this article we discuss about four ways of optimizing ASP.NET MVC sites"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`In this article we discuss about four ways of optimizing ASP.NET MVC sites:`}</p>
    <ul>
      <li parentName="ul">{`Caching`}</li>
      <li parentName="ul">{`Child actions`}</li>
      <li parentName="ul">{`Asynchronous actions`}</li>
      <li parentName="ul">{`Content bundling and minification`}</li>
    </ul>
    <h2>{`Introduction`}</h2>
    <p>{`There are three primary players involved when it comes to optimization:`}</p>
    <ul>
      <li parentName="ul">{`Server - Respond as faster`}</li>
      <li parentName="ul">{`Client - Render page as faster`}</li>
      <li parentName="ul">{`Internet - Send server's response to the client faster`}</li>
    </ul>
    <p>{`When it comes to optimization, we don't have much control over the internet, but we could reduce the amount of requests.
Client-side code can be optimized by ## content bundling and##  minification.
Common server tasks include:`}</p>
    <ul>
      <li parentName="ul">{`Interacting with database`}</li>
      <li parentName="ul">{`Logic `}{`&`}{` data manipulation`}</li>
      <li parentName="ul">{`Rendering HTML to the browser`}</li>
    </ul>
    <p>{`Optimizing database interactions`}</p>
    <ul>
      <li parentName="ul">{`Optimizing data for a limited period of time (caching)`}</li>
    </ul>
    <ul>
      <li parentName="ul">{`raw data`}</li>
      <li parentName="ul">{`fully rendered pages`}</li>
      <li parentName="ul">{`pieces of pages`}</li>
    </ul>
    <ul>
      <li parentName="ul">{`Interacting with the database on a separate thread`}</li>
    </ul>
    <p>{`Output caching`}</p>
    <ul>
      <li parentName="ul">{`Caching fully rendered HTML`}</li>
      <li parentName="ul">{`Caching part of the HTML`}</li>
      <li parentName="ul">{`caching the raw data`}</li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-csharp"
      }}>{`using System.Web;
using System.Web.Optimization;

namespace Auction
{
     public class BundleConfig

     // For more information on Bundling, visit http://go.microsoft.com/fwlink/?LinkId=254725
     public static void RegisterBundles(BundleCollection bundles)
     {
        bundles.Add(new ScriptBundle("~/bundles/jquery").Include(
                                     "~/Scripts/jquery-{version}.js"));
        bundles.Add(new ScriptBundle("~/bundles/jqueryui").Include(
                                     "~/Scripts/jquery-ui-{version}.js"));
        bundles.Add(new ScriptBundle("~/bundles/jqueryval").Include(
                                     "~/Scripts/jquery.unobtrusive*",
                                     "~/Scripts/jquery.validate*"));
`}</code></pre>
    <h2>{`Microcaching`}</h2>
    <p>{`Caching a for a very short period time like one second. This is required in instances like reducing thousands of database queries per second. This reduces the bottleneck for database access.`}</p>
    <h2>{`Child Controllers/ Child Actions`}</h2>
    <p>{`A Child Action in MVC is similar to  a `}<inlineCode parentName="p">{`User Control`}</inlineCode>{` in ASP.Net web forms. It allows for a controller to execute for a portion of the rendered area of a view, just like in Web Forms where you can execute a `}<inlineCode parentName="p">{`UserControl`}</inlineCode>{` for a portion of the rendered area of a page.
Child Actions can be very powerful especially when you want to have re-usable controller code to execute that you otherwise wouldn't want executing inside of your view. This makes unit testing simpler because only controller code need to be tested, not the code in a view.`}</p>
    <h2>{`Asynchronous Actions`}</h2>
    <p>{`When there's a long-running operation, you can't apply output caching. Asynchronous actions can be used to resolve this problem by scaling your site for more concurrent users.`}</p>
    <h2>{`ASP.NET Thread Pooling`}</h2>
    <p>{`When a request is made on your ASP.NET website, it calls a vacant thread from the thread pool. If there are lots of requests, and the thread pool runs out of free threads, your website will stop responding. To mitigate this issue, asynchronous actions can be used to queue the requests.`}</p>
    <p><img parentName="p" {...{
        "src": "https://i.imgur.com/AXQjqpe.png",
        "alt": "Thread pool"
      }}></img></p>
    <h2>{`When to use asynchronous controller`}</h2>
    <ul>
      <li parentName="ul">{`Long-running operations (eg:- longer than a second)`}</li>
      <li parentName="ul">{`When a user may cancel a long-running operation`}</li>
      <li parentName="ul">{`I/O intensive  or network related operations (efficiency)`}</li>
    </ul>
    <h2>{`When NOT to use the asynchronous controller`}</h2>
    <ul>
      <li parentName="ul">{`Short running operations`}</li>
      <li parentName="ul">{`CPU intensive operations`}</li>
      <li parentName="ul">{`Simple operations (simplicity)`}</li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-csharp"
      }}>{`using System;
using System.Collections.Generic;
using System.Linq;
using System.Threading.Tasks;
sing System.Web;
using System.Web.Mvc;
namespace Auction.Controllers
{
   public class SearchController : AsyncController
   {
     public async Task<ActionResult> Auctions(string keyword)  
     {  
        var auctions = await Task.Run<IEnumerable<Models.Auction>>(
          () =>
          {
              var db = new Models.AuctionDataContext();
              return db.Auction.Where(x => x.Title.Contains(keyword)).ToArray();
          });
              
          return Json(auctions, JsonRequestBehavior.AllowGet);
     }
   }
}
`}</code></pre>
    <h2>{`4. Bundling and minification`}</h2>
    <p>{`Modern browsers limit open connection to 6. Rendering stops to wait for some scripts to finish downloading.
Bundling(reduces the number of files) and minification(makes files smaller) can be used for reducing page load times. .NET framework provides `}<inlineCode parentName="p">{`System.Web.Optimization`}</inlineCode>{` library for this purpose.`}</p>
    <p><img parentName="p" {...{
        "src": "https://i.imgur.com/sBiqq95.png",
        "alt": "default_apppool_sql"
      }}></img></p>
    <h2>{`Bundling`}</h2>
    <p>{`Bundling compiles multiple files into one request:
You can register your bundles on the `}<inlineCode parentName="p">{`## BundleConfig.cs`}</inlineCode>{` file of App_Start folder.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-csharp"
      }}>{`using System.Web;
using System.Web.Optimization;

namespace Auction
{
public class BundleConfig
{
// For more information on Bundling, visit http://go.microsoft.com/fwlink/?LinkId=254725
public static void RegisterBundles(BundleCollection bundles)
{
bundles.Add(new ScriptBundle("~/bundles/jquery").Include(
"~/Scripts/jquery-{version}.js"));

bundles.Add(new ScriptBundle("~/bundles/jqueryui").Include(
"~/Scripts/jquery-ui-{version}.js"));

bundles.Add(new ScriptBundle("~/bundles/jqueryval").Include(
"~/Scripts/jquery.unobtrusive*",
"~/Scripts/jquery.validate*"));
`}</code></pre>
    <p>{`Note that line 15 uses a version placeholder and line 19 uses a wild card to look for similar files.
Styles and script files should be references on `}<inlineCode parentName="p">{`_Layout.cshml`}</inlineCode>{` file.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-csharp"
      }}>{`@ViewBag.Title - My ASP.NET MVC Application

@Styles.Render("~/Content/css")
@Scripts.Render("~/bundles/modernizr")
`}</code></pre>
    <p>{`Set the debug flag to false on your `}<inlineCode parentName="p">{`Web.config`}</inlineCode>{` file`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-csharp"
      }}>{`<system.web>
  <compilation debug="false" targetFramework="4.5" />
  <httpRuntime targetFramework="4.5" />
  <authentication mode="Forms">
  <forms loginUrl="~/Account/Login" timeout="2880" />
  </authentication>
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      